import "./App.css";
import Login from "./composant/login/login";
// import FiltreProjectFavori from "./composant/filtreProjectFavori/filtreProjectFavori";
// import MaintenenceGlobal from "./composant/MaintenanceGlobal/MaintenenceGlobal";
// import { BrowserRouter } from "react-router-dom";
// import axios from "axios";                                                                                                                                                                                             

export const URL_PATH = "https://maintenance.argos-v19.itmsol.fr/";
// export const URL_PATH = "http://127.0.0.1:8510/";
// export let URL_PATH;
// switch (document.domain) {
//     case "127.0.0.1:8510":
//       URL_PATH = "http://127.0.0.1:8510/";
//         break;
//     case "maintenance.argos-v19.itmsol.fr/":
//       URL_PATH = "https://maintenance.argos-v19.itmsol.fr/";
//         break;

//     default:
//       URL_PATH = "http://127.0.0.1:8510/";
// }
export const URL_REQUEST = {
  login: URL_PATH + "authentification/login", // POST
  check: URL_PATH + "authentification/check", // GET
  logout: URL_PATH + "authentification/logout", // GET
  projects: URL_PATH + "projects", // GET
  projects_user: URL_PATH + "projects/user", // POST
  sensors_types: URL_PATH + "sensors/types", // POST
  sensors_data: URL_PATH + "sensors/data", // POST
  sensors_data_sonometro: URL_PATH + "sensors/sonometro", // POST
  sensors_data_accelerometro: URL_PATH + "sensors/accelerometro", // POST
};

function App() {
  return <Login />;
}

export default App;
