import React, { Component } from "react";
import {Button} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import "./TableauGeneral.css";
import SimpleDateTime  from 'react-simple-timestamp-to-date';
import axios from "../../api/axios";
import {VscSearch} from "react-icons/vsc";
import { URL_PATH } from "../../App";
import paginationFactory from 'react-bootstrap-table2-paginator';
class TableauGeneral extends Component {

  afficherFiltre = (dataField, placeholder) => {
    const newCol = this.state.columns.map((col) => {
        if (col.dataField === dataField) {
       
            return {
                ...col, filter: textFilter({
                        placeholder: placeholder,
                        
                    
                    }
                ),
                hidden:false,
                
            }
        } else {
            return col
        }
    })
    this.setState({
      ...this.state,
      columns: [...newCol],
 
  })
}
  state = {
    GtotalComp:0,
    GreenCap : 0,
    RedCap : 0,
    projects_list: [],
    sensor_types_list: [],
    products: [],
    favorite_projects_list: this.props.favorite_projects_list,
    data_actul:0,
    is_loading_data:true,
    actualisation_header: false,
    columns: [
      {
        dataField: "sensor_id",

        text: [ "ID capteur", <button className="btn_serc" onClick={() => this.afficherFiltre("sensor_id", "ID capteur")}> <span><VscSearch size={20}/></span>
        </button>],
        headerAlign: 'center',
        sort: true,
       
      },
      {
        dataField: "sensor_type_name",

        text: [ "Type", <button className="btn_serc" onClick={() => this.afficherFiltre("sensor_type_name", "Type")}> <span><VscSearch size={20}/></span>
        </button>],
        headerAlign: 'center',
        sort: true,
     
      },
      {
        dataField: "project_name",

        text:  [ "Nom Projet", <button className="btn_serc" onClick={() => this.afficherFiltre("project_name", "Nom Projet")}> <span><VscSearch size={20}/></span>
        </button>],
        headerAlign: 'center',
        sort: true,
      

       
      
      },

      {
        dataField: "project_id",
        headerAlign: 'center',
        text: [ "ID projet", <button className="btn_serc" onClick={() => this.afficherFiltre("project_id", "ID projet")}> <span><VscSearch size={20}/></span>
        </button>] ,

        sort: true,
        
       
      },

    

      {
        dataField: "sensor_group",
        headerAlign: 'center',
        text: [ "Groupe", <button className="btn_serc" onClick={() => this.afficherFiltre("sensor_group", "Nom Groupe")}> <span><VscSearch size={20}/></span>
        </button>], 
      
         
        sort: true,
    
     
      },

      {
        dataField: "sensor_name",
        headerAlign: 'center',
        text: [ "Nom capteur", <button className="btn_serc" onClick={() => this.afficherFiltre("sensor_name", "Nom capteur")}> <span><VscSearch size={20}/></span>
        </button>],
      

        sort: true,
       
      },


      {
        dataField: "last_timestamp",
        headerAlign: 'center',
        text: [ "Dernier envoi", <button className="btn_serc" onClick={() => this.afficherFiltre("last_timestamp", "Dernier envoi")}> <span><VscSearch size={20}/></span>
          </button>],

        sort: true,

     
      },
      {
        dataField: "maintenance",
        headerAlign: 'center',
        text: [ "Maintenance JJ:HH:MM", <button className="btn_serc" > <span><VscSearch size={20}/></span>
          </button>],
        sort: true,
        formatter: (cellContent) => {
          const DataActuel = Date.now() / 1000;


          const calculeTotal = (parseInt((DataActuel - cellContent) / 60  / 60) / 24).toFixed() +  ":" + (parseInt((DataActuel - cellContent) / 60  / 60)).toFixed() + ":" + (parseInt((((DataActuel - cellContent) / 60  / 60) - parseInt((DataActuel - cellContent) / 60  / 60))*60)).toFixed();
          if ((DataActuel - cellContent)  > 21600 ){
            
            return (
              <h5>
                <span style={{backgroundColor:"red", color:"white"}}> {calculeTotal}</span>
                
              </h5>
            );
          }else{
          return (
            <h5>
              <span style={{backgroundColor:"green", color:"white"}}> {calculeTotal}</span>
           
            </h5>
          );
        }
        },
       
     
      },
 
    ],

  };
  


  async getDataTotal() {
    const SENSORS_DATA_URL = URL_PATH + "sensors/sonometro";

    const response = await axios.post(
      SENSORS_DATA_URL,

      JSON.stringify({
        projects_list: this.props.favorite_projects_list ,
      }),

      {
        headers: { "Content-Type": "application/json" },

        withCredentials: true,
      }
    ); // console.log(URL_PATH, SENSORS_DATA_URL);

    const sensors_data = response;
    const errors = response.data.errors;
    const DataActuel = sensors_data?.data.actual_timestamp;
    const donneTableu = sensors_data.data.sensor_data_list;

    console.log("total", sensors_data?.data);

    console.log("time actuel", DataActuel);

    // for (const elem_t of FiltreDonne) {
    //   lines_t.push({})}
   
  

        // Vérification du contenu de la réponse
        
    if (
      sensors_data !== undefined &&
      errors !== undefined &&
      Array.isArray(errors) &&
      Array.isArray(sensors_data)
    ) {
      // Des erreurs sont présentes dans la réponse !
      if (errors.length > 0) {
        for (const error of errors) {
          // Non authentifié
          if (error.id === -3) {
            this.setState({
              is_connected: false,
            });
          }
        }
      }
    }
 

    if (sensors_data.data !== undefined) {
      // Vérification que le serveur renvoit les champs nécessaires
      var FiltreDonne = [...new Set(donneTableu)]
      if (
        sensors_data.data.errors !== undefined &&
        Array.isArray(sensors_data.data.errors) &&
        sensors_data.data.sensor_data_list !== undefined &&
        Array.isArray(sensors_data.data.sensor_data_list)
      ) {
        console.log(sensors_data.data.errors);

       
       
      //  let red = DataActuel - donneTableu.map(last_timestamp =>last_timestamp )

        if (sensors_data.data.errors.length === 0) {
          var lines_t = [];
          console.log( 'test', FiltreDonne);    
          for (const elem_t of FiltreDonne) {
            lines_t.push({
              // component_id: elem_t.component_id,

              // component_name:  elem_t.component_name,

              // component_position: elem_t.component_position,

              // last_calc_value: elem_t.last_calc_value,
              sensor_id: elem_t.sensor_id,

              sensor_name: elem_t.sensor_name,

              sensor_type_id: elem_t.sensor_type_id,

              sensor_type_name: elem_t.sensor_type_name,
              
              last_timestamp: <SimpleDateTime dateSeparator="-"  timeSeparator=":" meridians="0" format="YMD">{(elem_t.last_timestamp)}</SimpleDateTime>,
              
              // maintenance: (parseInt((DataActuel - elem_t.last_timestamp) / 60  / 60) / 24).toFixed() +  ":" + (parseInt((DataActuel - elem_t.last_timestamp) / 60  / 60)).toFixed() + ":" + (parseInt((((DataActuel - elem_t.last_timestamp) / 60  / 60) - parseInt((DataActuel - elem_t.last_timestamp) / 60  / 60))*60)).toFixed(),

              maintenance:  elem_t.last_timestamp,
              
              maintenanceultime: elem_t.last_timestamp,
              
              project_id: elem_t.project_id,

              project_name: elem_t.project_name,

              sensor_group: elem_t.sensor_group,

             
            });
            
          }
          let valeurMaintenance = lines_t.map(({ maintenanceultime}) => maintenanceultime);
          let arrNewGreen = 0
          let arrNewRed = 0
 
          
          for(let indse = 0; indse<valeurMaintenance.length;indse++){


           if( DataActuel - valeurMaintenance[indse] < 21600){
            arrNewGreen++
            this.setState({
              GreenCap : arrNewGreen,
            })
           
            }else{
              arrNewRed++
              this.setState({
                RedCap : arrNewRed,
              })
            }
            this.setState({
              GtotalComp: arrNewGreen + arrNewRed, 
            })
          }
         
          console.log("array sans le traite oly sensor_group", lines_t);

          const uniqueArray = lines_t.filter((value, index) => {
            const _value = JSON.stringify(value);
            return index === lines_t.findIndex(obj => {
              return JSON.stringify(obj) === _value;
            });
            
          });

      
          this.setState({
         
            products: uniqueArray,
            data_actul: DataActuel,
            actualisation_header: true,
            is_loading_data:false,
            
          }); // this.state.products = lines_t;
     
         
          
        } else {
          
          console.log("ERR");
        }
      }
    }
  }

  componentDidMount() {

   
  }
  componentDidUpdate() {
    if (this.state.is_loading_data === false && this.state.actualisation_header === true){
      
    
      let filterArray = this.state.products.map(({ sensor_group}, ind) => sensor_group)
      let valeursUnique = []
      for(let indLabel=0; indLabel<filterArray.length;indLabel++){
        if (valeursUnique.indexOf(filterArray[indLabel])<0){
          valeursUnique.push(filterArray[indLabel])
        }
      }
      filterArray = valeursUnique.map((sensor_group, ind) => ({value: sensor_group, label: sensor_group}))
      console.log("array  traite", filterArray);
      let newColumns = this.state.columns;
      newColumns[4]={
        dataField: "sensor_group",
        text:  "Groupe",
        sort: true,
        filter: selectFilter({
          options: filterArray
        })
      }


     



      this.setState({columns:newColumns, actualisation_header: false, newTable: filterArray,})
    }

  }

  render() 
  
  {
    // console.log("gold main", donneTableu);
    return (
     <>
      <Button
                            
                            className="btn_go"
                            variant="alert"
                            onClick={() => this.getDataTotal()}
                          >
                            {" "}
                            Charger les données des tous mes Projets Favoris{" "}
                          </Button>
     {this.state.is_loading_data ? (
      <h6><strong>Tableau de Maintenance</strong></h6>
      
    ):(
      <div className="container" style={{ marginTop: "10px" }}>
        <div style={{ textAlign:'Right'}}>
          <span style={{ fontSize:'20px',padding:"5px", marginRight: "10px" }}>Total de composant Off : <span style={{backgroundColor: 'red', color: 'white', fontSize:'20px', padding:"5px"}}>{this.state.RedCap} / {this.state.GtotalComp}</span></span>
          <span style={{ fontSize:'20px', padding:"5px" }}>Total de composant on : <span style={{backgroundColor: 'green', color: 'white', fontSize:'20px', padding:"5px"}}>{this.state.GreenCap} / {this.state.GtotalComp}</span></span>
         
        </div>
       <BootstrapTable
        condensed
        striped
        hover
        keyField="sensor_id"
        data={this.state.products}
        columns={this.state.columns}
        filter={ filterFactory() } 
        pagination={ paginationFactory()}
      />
      </div>
      )
      }
       </>
    );
   
  }
  
}

export default TableauGeneral;
