import { Component } from "react";

import logo from "../../ressource/argos_png_logo.png";
import { Button, Container, Form } from "react-bootstrap";
import "./login.css";
import axios from "../../api/axios";
import MaintenanceGlobal from "../MaintenanceGlobal/MaintenenceGlobal";
import {  URL_REQUEST } from "../../App";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: "",
      pwd: "",
      connected: "",
      Id_acces: "",
      success: false,
      ErrMsg: "",
      check: false,
    };
  }

  async getProjects() {
    const check = URL_REQUEST.check;
    const res = await axios.get(check, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    let conn = res?.data;
    console.log("reauete check", conn);
    if (res.data.errors.length === 0) {
      this.setState({
        success: true,
        check: true,
      });
    } else {
      this.setState({
        check: true,
      });
    }
  }


  
  handleSumit = async (e) => {
    e.preventDefault();
    const LOGIN_URL = URL_REQUEST.login;
    try {
      const response = await axios.post(
        LOGIN_URL,
        JSON.stringify({
          login_id: this.state.user,
          login_password: this.state.pwd,
        }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );

      console.log(JSON.stringify("response", response?.data?.user_id));
      console.log(JSON.stringify("response2",response));

      const accessId = response?.data?.user_id;
      const userName = response?.data?.login_id;

     
    
      if (accessId !== null) {
        this.setState({ success: true });
      } else{
        this.setState({ ErrMsg: "Login Failed !!" });
      }
      if (userName !== null) {
        this.setState({ ErrMsg: "Email ou Mot de Passe Incorrect " });
      }

    } catch (err) {
      if (!err?.response) {
        this.setState({ ErrMsg: "PAS de réponse du serveur" });
      } else if (err.response?.data?.user_id === null) {
        this.setState({ ErrMsg: "Missing UserName or Password" });
      } else if (err.response?.data?.user_id === null) {
        this.setState({ ErrMsg: "Unauthorized" });
      } else {
        this.setState({ ErrMsg: "Login Failed!" });
      }
    }
  };

  handleChangeUser = (e) => {
    this.setState({ user: e.target.value });
  };

  handleChangePwd = (e) => {
    this.setState({ pwd: e.target.value });
  };

  componentDidMount() {
    this.getProjects();
  }

  render() {
    return (
      <>
        {this.state.check ? (
          <div className="contentPrincipal">
            {this.state.success ? (
              <MaintenanceGlobal is_connected={true} />
            ) : (
              <Container>
                {/* error message block -----------------------------------------------------------------------------*/}
                <div className="content">
                  <p>{this.state.ErrMsg}</p>
                </div>
                {/* error message block -----------------------------------------------------------------------------*/}

                {/* Form login -----------------------------------------------------------------------------*/}
                <Form onSubmit={this.handleSumit} className="formulaire">
                  <img src={logo} className="persoN" alt="logo" />
                  <Form.Group className="mb-3 row" controlId="formBasicEmail">
                    <label className="label">e-mail</label>

                    <input
                      className="inputP"
                      id="username"
                      onChange={this.handleChangeUser}
                      value={this.state.user}
                      type="text"
                      placeholder="e-mail"
                      // pattern=".+@globex\.com"
                      size="30"
                      required
                    />
                  </Form.Group>

                  <Form.Group
                    className="mb-3 row"
                    controlId="formBasicPassword"
                  >
                    <label className="label">Mot de passe</label>
                    <input
                      className="inputP"
                      type="password"
                      placeholder="Mot de passe"
                      id="password"
                      onChange={this.handleChangePwd}
                      value={this.state.pwd}
                      required
                    />
                  </Form.Group>
                  <div className="btnNew">
                    <Button
                    
                      className="btn btn-dark"
                      variant="primary"
                      type="submit"
                    >
                      Connexion
                    </Button>
                  </div>
                </Form>
                {/* Form login -----------------------------------------------------------------------------*/}
              </Container>
            )}
          </div>
        ) : (
          <div className="loader">
                  
          </div>
  
        )}
      </>
    );
  }
}

export default Login;
