import {Row, Col, Card, Button} from "react-bootstrap";
import SimpleDateTime  from 'react-simple-timestamp-to-date';
import { Component } from "react";
import axios from "../../api/axios";
import Tableau from "../tableau/tableau.js";
// import TableauSono from "../SONO/SONO.js";
import TableauGeneral from "../TableauGeneral/TableauGeneral.js";
import { IoIosAddCircle } from "react-icons/io";
import FiltreTypeCapteur from "../FiltreTypeCapteur/FiltreTypeCapteur";
import "./MaintenanceGlobal.css";
import { FaPowerOff } from "react-icons/fa";
import { URL_PATH, URL_REQUEST } from "../../App";
import Filtre from "../filtreProjectFavori/filtreProjectFavori";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
class MaintenenceGlobal extends Component {
  // Définition du constructeur
  constructor(props) {
    super(props);
    this.state = {
      actualisation_header:false,
      GtotalComp:0,
      GreenCap : 0,
      RedCap : 0,
      is_loading_data_tableuGeral:false,
      is_connected: true,
      is_loading: true,
      page_filtre: false,
      projects_list: [],
      favorite_projects_list: [],
      sensor_types_list: [],
      logoff: [],
      is_loading_data: false,
      valide:true,
      valideSono:true,
      valideAccelero:true, 
      sensor_data: {
        sensor_data_list: [],
        actual_timestamp: null,
      },
      products: [],
      data_actul:0,
      tableu:false,
    };
  }

  // Récupération des projets
  async getProjects() {
    // DEBUG
    console.log("REQUEST get project list : ", URL_REQUEST.projects, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });

    // Requête
    const response = await axios.get(URL_REQUEST.projects, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });

    // DEBUG
    console.log("RESPONSE Get projects : ", response);

    const favorite_projects_list = response.data.favorite_projects_list;
    const projects_list = response.data.projects_list;
    const errors = response.data.errors;



    if (
      favorite_projects_list !== undefined &&
      projects_list !== undefined &&
      errors !== undefined &&
      Array.isArray(errors) &&
      Array.isArray(favorite_projects_list) &&
      Array.isArray(projects_list)
    ) {
      // Des erreurs sont présentes dans la réponse !
      if (errors.length > 0) {
        for (const error of errors) {
          console.warn("erreur réponse : ", response);

          // Non authentifié
          if (error.id === -3) {
            this.setState({
              is_connected: false,
            });
          }
        }
      }

      if (favorite_projects_list.length === 0) {
        // Update page_filtre
        this.setState({ page_filtre: true });
      } else {
        // Update page_filtre and favorite_projects_list state
        this.setState({
          favorite_projects_list: favorite_projects_list,
          page_filtre: false,
        });
      }
    } else {
      console.warn("erreur réponse : ", response);
      this.setState({
        is_connected: false,
      });
    }

    console.log(
      "Page Filtre : ",
      this.state.page_filtre,
      "Liste de project favorie : ",
      this.state.favorite_projects_list
    );

    // Fin de chargement
    this.setState({
      is_loading: false,
    });
  }

  // Logout action
  logout = async (e) => {
        e.preventDefault();

        // DEBUG
        console.log("REQUEST logout : ", URL_REQUEST.logout, {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        });

        const response = await axios.get(URL_REQUEST.logout, {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        });

        // DEBUG
        console.log("RESPONSE  logout: ", response);

        const errors = response.data.errors;

        // Vérification du contenu de la réponse
        if (errors !== null && Array.isArray(errors)) {
          // Des erreurs sont présentes dans la réponse !
          if (errors.length > 0) {
            for (const error of errors) {
              console.warn("erreur réponse logout : ", response);

              // Non authentifié
              if (error.id === -3) {
                this.setState({
                  is_connected: false,
                });
              }
            }
          }

          // Everything seems fine
          // Déconnexion
          this.setState({
            is_connected: false,
          });
        }

        // Déconnexion par défaut
        this.setState({
          is_connected: false,
        });
  };
  //Fin Logout
  
  //Récupération des donnes
  async getData() {

    this.setState({
      is_loading_data: true,
    });
    const SENSORS_DATA_URL = URL_PATH + "sensors/data";

    const response = await axios.post(
      SENSORS_DATA_URL,

      JSON.stringify({
        projects_list: this.state.favorite_projects_list ,
        // projects_list: [63, 74],

        // sensor_types_list: [1, 35],

        sensor_types_list:this.state.sensor_types_list
      }),

      {
        headers: { "Content-Type": "application/json" },

        withCredentials: true,
      }
    ); // console.log(URL_PATH, SENSORS_DATA_URL);
  console.log("mes project favories: ",this.state.favorite_projects_list )
    const sensors_data = response;
    const errors = response.data.errors;
    const DataActuel = sensors_data?.data.actual_timestamp;
    const donneTableu = sensors_data.data.sensor_data_list;

    console.log("data de requete sensor data", sensors_data?.data);

    console.log("time actuel", DataActuel);

    // for (const elem_t of FiltreDonne) {
    //   lines_t.push({})}
   
  

        // Vérification du contenu de la réponse
        
    if (
      sensors_data !== undefined &&
      errors !== undefined &&
      Array.isArray(errors) &&
      Array.isArray(sensors_data)
    ) {
      // Des erreurs sont présentes dans la réponse !
      if (errors.length > 0) {
        for (const error of errors) {
          // Non authentifié
          if (error.id === -3) {
            this.setState({
              is_connected: false,
            });
          }
        }
      }
    }
 

    if (sensors_data.data !== undefined) {
      // Vérification que le serveur renvoit les champs nécessaires
      var FiltreDonne = [...new Set(donneTableu)]
      if (
        sensors_data.data.errors !== undefined &&
        Array.isArray(sensors_data.data.errors) &&
        sensors_data.data.sensor_data_list !== undefined &&
        Array.isArray(sensors_data.data.sensor_data_list)
      ) {
        console.log(sensors_data.data.errors);

       
       
      //  let red = DataActuel - donneTableu.map(last_timestamp =>last_timestamp )

        if (sensors_data.data.errors.length === 0) {
          var lines_t = [];
          // console.log( 'test', FiltreDonne);    
          for (const elem_t of FiltreDonne) {
            lines_t.push({
              // component_id: elem_t.component_id,

              // component_name:  elem_t.component_name,

              // component_position: elem_t.component_position,

              // last_calc_value: elem_t.last_calc_value,
              sensor_id: elem_t.sensor_id,

              sensor_name: elem_t.sensor_name,

              sensor_type_id: elem_t.sensor_type_id,

              sensor_type_name: elem_t.sensor_type_name,
              
              last_timestamp: <SimpleDateTime dateSeparator="-"  timeSeparator=":" meridians="0" format="YMD">{(elem_t.last_timestamp)}</SimpleDateTime>,
              
              // maintenance: (parseInt((DataActuel - elem_t.last_timestamp) / 60  / 60) / 24).toFixed() +  ":" + (parseInt((DataActuel - elem_t.last_timestamp) / 60  / 60)).toFixed() + ":" + (parseInt((((DataActuel - elem_t.last_timestamp) / 60  / 60) - parseInt((DataActuel - elem_t.last_timestamp) / 60  / 60))*60)).toFixed(),

              maintenance:  elem_t.last_timestamp,
              
              maintenanceultime: elem_t.last_timestamp,
              
              project_id: elem_t.project_id,

              project_name: elem_t.project_name,

              sensor_group: elem_t.sensor_group,

             
            });
            
          }
          let valeurMaintenance = lines_t.map(({ maintenanceultime}) => maintenanceultime);
          let arrNewGreen = 0
          let arrNewRed = 0
 
          
          for(let indse = 0; indse<valeurMaintenance.length;indse++){

        
           if(  0 <= valeurMaintenance[indse] && DataActuel - valeurMaintenance[indse] <= 21600  ){

            arrNewGreen++
            this.setState({
              GreenCap : arrNewGreen,
            })
           
            }else{
              arrNewRed++
              this.setState({
                RedCap : arrNewRed,
              })
            }
            this.setState({
              GtotalComp: arrNewGreen + arrNewRed, 
            })
          }
         
          // console.log("array sans le traite oly sensor_group", lines_t);

          const uniqueArray = lines_t.filter((value, index) => {
            const _value = JSON.stringify(value);
            return index === lines_t.findIndex(obj => {
              return JSON.stringify(obj) === _value;
            });
            
          });

      
          this.setState({
         
            products: uniqueArray,
            data_actul: DataActuel,
            actualisation_header: true,
            is_loading_data:false,
            
          }); // this.state.products = lines_t;
     
         
          
        } else {
          
          console.log("wait for DATA SENSOR");
        }
      }
    }
  }


 

  set_new_type = (type_list) => {
    console.log("new type in state : ", type_list);
    this.setState({
      sensor_types_list: type_list,
      valide:false,
      GreenCap:0,
      RedCap:0

    });
  };

  set_on_filtre = (e) => {
    e.preventDefault();
    this.setState({
      page_filtre: true,
    });
  };

  set_new_favorite_projects_list = (favorite_projects_list) => {
    this.setState({
      favorite_projects_list: favorite_projects_list,
      page_filtre: false,
    });
  };

  componentDidMount() {
    // Récupération des projets
    this.getProjects();
  
  }

  render() {
    // Redirige vers le composant login
    if (this.state.is_connected === false) {
      window.location.replace(URL_PATH);
    }

    // // Ouvre Filtre si la liste des favoris est vide c'est conditon arret l'affichage en local
    // if (this.state.favorite_projects_list.length === 0) {
    //   this.setState({
    //     page_filtre: true,
    //   });
    // }
   
    if (this.state.is_loading) {
      // Loading (wait request)
      return  <div className="loaderPetit"></div>;
    } else {
      if (this.state.page_filtre) {
        // Filtre component
        return (
          <Filtre
            favorite_projects_list={[...this.state.favorite_projects_list]}
            set_new_favorite_projects_list={this.set_new_favorite_projects_list}
          ></Filtre>
        );
      } else {
        // console.log('liste de project favories', this.state.favorite_projects_list);
        // MaintenanceGlobal content
        return (
          <Tabs
          defaultActiveKey="Tableau detaille"
          id="uncontrolled-tab-example"
          className="mb-3">
                <Tab eventKey="Tableau detaille" title="Tableau detaille">
                  <div className="containerP">
                      
                      <Card>
                        <Card.Header>
                          <Row>
                            <Col xl={6}>
                              <spam className="title">
                            
                                Types de capteur présent dans le projet
                              </spam>
                            </Col>
                            
                            <Col xl={4}>
                              <Button
                                onClick={(e) => this.set_on_filtre(e)}
                                className="btn_go Margin"
                                variant="alert"
                              >
                                <IoIosAddCircle size={20} />  
                                <spam className="title">
                          
                                  Projets Favoris
                                </spam>
                              </Button>
                            </Col>
                            <Col xl={2}>
                              <Button
                                onClick={this.logout}
                                className="logautBTn"
                                variant="alert"
                              >
                                <FaPowerOff size={23} />
                                {/* <spam className="logaut"> Modifier Mes Projet Favori </spam> */}
                              </Button>
                            </Col>
                          </Row>
                        </Card.Header>
          
                        <Card.Body>
                          <Row>
                            <Col sm={12}>
                              <Card.Body>
                                <FiltreTypeCapteur
                                  favorite_projects_list={
                                    this.state.favorite_projects_list
                                  
                                  }
                                  selected_sensor_types_list={
                                    this.state.sensor_types_list
                                  }
                                  set_new_type={(l) => this.set_new_type(l)}
                                />
                              </Card.Body>
                            </Col>
                          
                      
                            <Button
                              disabled={ this.state.valide}
                              className="btn_go"
                              variant="alert"
                              onClick={() => this.getData()}
                            >
                              {" "}
                              Charger les données{" "}
                            </Button>
                          
                          </Row>
                        </Card.Body>
                      </Card>
          
                      <Row>
                        <Col xl={12}>
                          <Card>
                            <Card.Header className="title"> Sélectionnez les types des capteurs pour afficher le tableau de Maintenance </Card.Header>
                            <Card.Body>
                              {this.state.is_loading_data ? (
                                <div className="loaderGrand"></div>
                              ) : (
                            
                              
                                <Tableau 
                                valide={
                                  this.state.valide
                                }
                                is_loading_data ={
                                  this.state.is_loading_data
                                }
                            actualisation_header={
                              this.state.actualisation_header
                            }
                                GtotalComp = {
                                  this.state.GtotalComp
                                }

                                GreenCap={
                                  this.state.GreenCap
                                } 
                                RedCap={
                                  this.state.RedCap
                                }
                                products={
                                  this.state.products
                                }
                                favorite_projects_list={
                                  this.state.favorite_projects_list
                                }
                                selected_sensor_types_list={
                                  this.state.sensor_types_list
                                }
                                
                                
                                />  
                                )}
                            </Card.Body>
                          </Card>
                        </Col>
                      </Row>
                    </div>
                </Tab>
                {/* <Tab eventKey="Tableau general " title=" Tableau general">
                                <TableauGeneral
                                favorite_projects_list={
                                  this.state.favorite_projects_list
                                }/>      
                               
                </Tab> */}
          </Tabs>
        
        );
      }
    }
  }
}

export default MaintenenceGlobal;
