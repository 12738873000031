import { React, Component } from "react";
import axios from "../../api/axios";

import {

  Form,

} from "react-bootstrap";

import "./filtreTypeCapteur.css";

import { URL_PATH, URL_REQUEST } from "../../App";

class FiltreTypeCapteur extends Component {
  state = {
    is_connected: true,
    is_loading: true,
    sensor_types_list: [],
    favorite_projects_list: this.props.favorite_projects_list,
    set_new_type: this.props.set_new_type,
    selected_sensor_types_list: this.props.selected_sensor_types_list,
  };

  async getTypes() {
    // DEBUG
    console.log(
      "REQUEST sensors_types,  : ",
      URL_REQUEST.sensors_types,
      JSON.stringify({ projects_list: this.state.favorite_projects_list }),
      {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      }
    );

    // Requête des types fonction des projets favoris
    const response = await axios.post(
      URL_REQUEST.sensors_types,
      JSON.stringify({ projects_list: this.state.favorite_projects_list }),
      {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      }
    );

    // DEBUG
    console.log("RESPONSE  de sensor type des mes project favorie: ", response);

    const sensor_types_list = response.data.sensor_types_list;
    const errors = response.data.errors;

    // Vérification du contenu de la réponse
    if (
      sensor_types_list !== null &&
      errors !== null &&
      Array.isArray(errors) &&
      Array.isArray(sensor_types_list)
    ) {
      // Des erreurs sont présentes dans la réponse !
      if (errors.length > 0) {
        for (const error of errors) {
          console.warn("erreur réponse : ", response);

          // Non authentifié
          if (error.id === -3) {
            this.setState({
              is_connected: false,
            });
          }
        }
      }

      // Everything seems fine
      // Chargement des types et fin du chargement
      this.setState({
        sensor_types_list: sensor_types_list,
        is_loading: false,
      });

      // Nous faisons remonter les types au composant supérieur
      // this.state.set_new_type(sensor_types_list);
    }
  }

  componentDidMount() {
    // Récupération des types
    this.getTypes();
  }

  get_checkbox = (e) => {
    // e.preventDefault();

    const target_elmt_id = parseInt(e.target.name, 10);

    let copy_of_selected_sensor_types_list = [
      ...this.state.selected_sensor_types_list,
    ];

    const flag_in =
      this.state.selected_sensor_types_list.includes(target_elmt_id);

    if (flag_in && !e.target.checked) {
      // Il faut enlever l'élément
      const index = copy_of_selected_sensor_types_list.indexOf(target_elmt_id);
      if (index > -1) {
        copy_of_selected_sensor_types_list.splice(index, 1); // 2nd parameter means remove one item only
      }
    } else if (!flag_in && e.target.checked) {
      // Il faut ajouter l'élément
      copy_of_selected_sensor_types_list.push(target_elmt_id);
    } else {
      // Nothing to do
      return;
    }

    // Mise à jour du state favorite_projects_list
    this.setState({
      selected_sensor_types_list: [...copy_of_selected_sensor_types_list],
    });

    this.state.set_new_type([...copy_of_selected_sensor_types_list]);
  };

  render() {
    // Redirige vers le composant login
    if (this.state.is_connected === false) {
      window.location.replace(URL_PATH);
    }

    if (this.state.is_loading) {
      return <div className="loader"></div>
    } else {
      return (
        <>
          <Form>
            {this.state.sensor_types_list.map((type) => (
              <Form.Check
                label={type.name}
                name={type.sensor_type_id}
                onChange={(e) => this.get_checkbox(e)}
                checked={
                  this.state.selected_sensor_types_list.includes(
                    parseInt(type.sensor_type_id, 10)
                  )
                    ? true
                    : false
                }
              />
            ))}
          </Form>
        </>
      );
    }
  }
}

export default FiltreTypeCapteur;
