import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import "./tableau.css";
import {VscSearch} from "react-icons/vsc";
import paginationFactory from 'react-bootstrap-table2-paginator';
class Tableau extends Component {

  afficherFiltre = (dataField, placeholder) => {
    const newCol = this.state.columns.map((col) => {
        if (col.dataField === dataField) {
       
            return {
                ...col, filter: textFilter({
                        placeholder: placeholder,
                        
                    
                    }
                ),
                hidden:false,
                
            }
        } else {
            return col
        }
    })
    this.setState({
      ...this.state,
      columns: [...newCol],
 
  })
}
  state = {

    valide:this.props.valide,
    GtotalComp:this.props.GtotalComp,
    GreenCap : this.props.GreenCap,
    RedCap : this.props.RedCap,
    projects_list: [],
    sensor_types_list: [],
    products: this.props.products,
    selected_sensor_types_list: this.props.selected_sensor_types_list,
    favorite_projects_list: this.props.favorite_projects_list,
    data_actul:0,
    is_loading_data:this.props.is_loading_data,
    actualisation_header: this.props.actualisation_header,
    columns: [
      {
        dataField: "sensor_id",
        headerAlign: 'center',
        text: [ "ID capteur", <button className="btn_serc" onClick={() => this.afficherFiltre("sensor_id", "ID capteur")}> <span><VscSearch size={20}/></span>
        </button>],
         style:{
          textAlign: "center"
          },
        sort: true,
        headerClasses: 'demo-row-odd'
      },
      {
        dataField: "sensor_type_name",
        headerAlign: 'center',
        text: [ "Type", <button className="btn_serc" onClick={() => this.afficherFiltre("sensor_type_name", "Type")}> <span><VscSearch size={20}/></span>
        </button>],
         style:{
          textAlign: "center"
          },
        sort: true,
        headerClasses: 'demo-row-odd',  
      },
      {
        dataField: "project_name",
        headerAlign: 'center',
        text:  [ "Nom Projet", <button className="btn_serc" onClick={() => this.afficherFiltre("project_name", "Nom Projet")}> <span><VscSearch size={20}/></span>
        </button>],
         style:{
          textAlign: "center"
          },
        sort: true,
        headerClasses: 'demo-row-odd',
      },

      {
        dataField: "project_id",
        headerAlign: 'center',
        text: [ "ID projet", <button className="btn_serc" onClick={() => this.afficherFiltre("project_id", "ID projet")}> <span><VscSearch size={20}/></span>
        </button>],
        style:{
          textAlign: "center"
        },
        sort: true,
        headerClasses: 'demo-row-odd'
      },

    

      {
        dataField: "sensor_group",
        headerAlign: 'center',
        text: [ "Groupe", <button className="btn_serc" onClick={() => this.afficherFiltre("sensor_group", "Nom Groupe")}> <span><VscSearch size={20}/></span>
        </button>], 
       style:{
        textAlign: "center"
        },
        sort: true,
        headerClasses: 'demo-row-odd',
      },

      {
        dataField: "sensor_name",
        headerAlign: 'center',
        text: [ "Nom capteur", <button className="btn_serc" onClick={() => this.afficherFiltre("sensor_name", "Nom capteur")}> <span><VscSearch size={20}/></span>
        </button>],
       style:{
        textAlign: "center"
      },
        sort: true,
        headerClasses: 'demo-row-odd'
      },


      {
        dataField: "last_timestamp",
        headerAlign: 'center',
        text: [ "Dernier envoi"],
        sort: true,
        style:{
          textAlign: "center"
        },
        headerClasses: 'demo-row-odd',
      },
      {
        dataField: "maintenance",
        headerAlign: 'center',
        text: [ "Maintenance JJ:HH:MM"],
        sort: true,
        formatter: (cellContent) => {
          const DataActuel = Date.now() / 1000;


          const calculeTotal = (parseInt((DataActuel - cellContent) / 60  / 60) / 24).toFixed() +  ":" + (parseInt((DataActuel - cellContent) / 60  / 60)).toFixed() + ":" + (parseInt((((DataActuel - cellContent) / 60  / 60) - parseInt((DataActuel - cellContent) / 60  / 60))*60)).toFixed();
          if ((DataActuel - cellContent)  > 21600 ){
            
            return (
              <h5>
                <span style={{backgroundColor:"red", color:"white"}}> {calculeTotal}</span>
                
              </h5>
            );
          }else{
          return (
            <h5>
              <span style={{backgroundColor:"green", color:"white"}}> {calculeTotal}</span>
           
            </h5>
          );
        }
        },
        style:{
          textAlign: "center"
        },
        headerClasses: 'demo-row-odd',
      },
      
    ],

  };

   

    componentDidMount() {
    if (this.state.is_loading_data === false && this.state.actualisation_header === true){
      
    
      let filterArray = this.state.products.map(({ sensor_group}, ind) => sensor_group)
      let valeursUnique = []
      for(let indLabel=0; indLabel<filterArray.length;indLabel++){
        if (valeursUnique.indexOf(filterArray[indLabel])<0){
          valeursUnique.push(filterArray[indLabel])
        }
      }
      filterArray = valeursUnique.map((sensor_group, ind) => ({value: sensor_group, label: sensor_group}))
      console.log("array  traite", filterArray);
      let newColumns = this.state.columns;
      newColumns[4]={
        dataField: "sensor_group",
        text:  "Groupe",
        sort: true,
        filter: selectFilter({
          options: filterArray
        })
      }


     



      this.setState({columns:newColumns, actualisation_header: false, newTable: filterArray,})
    }

  }

  render() 
  
  {
    // console.log("gold main", donneTableu);
    return (
     <>
     {this.state.valide ? (
      <h6><strong>Tableau de Maintenance</strong></h6>
    ):(
      <div className="container" style={{ marginTop: "10px" }}>
        <div style={{ textAlign:'Right'}}>
          <span style={{ fontSize:'20px',padding:"5px", marginRight: "10px" }}>Total de composant Off : <span style={{backgroundColor: 'red', color: 'white', fontSize:'20px', padding:"5px"}}>{this.state.RedCap} / {this.state.GtotalComp}</span></span>
          <span style={{ fontSize:'20px', padding:"5px" }}>Total de composant on : <span style={{backgroundColor: 'green', color: 'white', fontSize:'20px', padding:"5px"}}>{this.state.GreenCap} / {this.state.GtotalComp}</span></span>
         
        </div>
       <BootstrapTable
        condensed
        striped
        hover
        keyField="sensor_id"
        data={this.state.products}
        columns={this.state.columns}
        filter={ filterFactory() } 
        pagination={ paginationFactory()}
      />
      </div>
      )
      }
       </>
    );
   
  }
  
}

export default Tableau;
