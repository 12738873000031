import React, { Component } from "react";
import { Button, Form } from "react-bootstrap";
// import FiltreProjectFavoriOne from '../filtreProjectFavoriOne/filtreProjectFavoriOne'
import "./filtreProjectFavori.css";
// import FiltreProjectFavoriOne from "../filtreProjectFavoriOne/filtreProjectFavoriOne";
import axios from "axios";
import { URL_PATH, URL_REQUEST } from "../../App";

class Filtre extends Component {
  state = {
    is_loading: true,
    PageFilre: false,
    projects_list: [],
    favorite_projects_list: this.props.favorite_projects_list,
    project_id: [],
    project_name: [],
    set_new_favorite_projects_list: this.props.set_new_favorite_projects_list,
  };

  async getData() {
    // DEBUG
    console.log("REQUEST : ", URL_REQUEST.projects, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });

    // Requête des types fonction des projets favoris
    const response = await axios.get(URL_REQUEST.projects, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });

    // DEBUG
    console.log("RESPONSE : ", response);

    const projects_list = response.data.projects_list;
    const errors = response.data.errors;

    // Vérification du contenu de la réponse
    if (
      projects_list != null &&
      errors != null &&
      Array.isArray(errors) &&
      Array.isArray(projects_list)
    ) {
      // Des erreurs sont présentes dans la réponse !
      if (errors.length > 0) {
        for (const error of errors) {
          console.warn("erreur réponse : ", response);

          // Non authentifié
          if (error.id === -3) {
            this.setState({
              is_connected: false,
            });
          }
        }
      }

      // Everything seems fine
      // Chargement des types et fin du chargement
      this.setState({
        projects_list: projects_list,
        is_loading: false,
      });

      // Nous faisons remonter les types au composant supérieur
      // this.state.set_new_type(sensor_types_list);
    }
  }

  componentDidMount() {
    this.getData();
  }

  update_favorite_projects_list_from_maintenance(e) {
    e.preventDefault();

    console.log("UPDATE FAVORIS");

    this.state.set_new_favorite_projects_list([
      ...this.state.favorite_projects_list,
    ]);
  }

  // Mets à jour la liste des id des projets favoris
  updateCheckbox = (e) => {
    // e.preventDefault();

    const target_elmt_id = parseInt(e.target.name, 10);

    let copy_of_favorite_projects_list = [...this.state.favorite_projects_list];

    const flag_in = this.state.favorite_projects_list.includes(target_elmt_id);

    // console.log(e.target.name, e.target.checked, target_elmt_id, flag_in);

    if (flag_in && !e.target.checked) {
      // Il faut enlever l'élément
      const index = copy_of_favorite_projects_list.indexOf(target_elmt_id);
      if (index > -1) {
        copy_of_favorite_projects_list.splice(index, 1);
      }
    } else if (!flag_in && e.target.checked) {
      // Il faut ajouter l'élément
      copy_of_favorite_projects_list.push(target_elmt_id);
    } else {
      // Nothing to do
      return;
    }

    // Mise à jour du state favorite_projects_list
    this.setState({
      favorite_projects_list: [...copy_of_favorite_projects_list],
    });
  };

  render() {
    // Redirige vers le composant login
    if (this.state.is_connected === false) {
      window.location.replace(URL_PATH);
    }

    // console.log(this.state.favorite_projects_list);

    if (this.state.is_loading) {
      return <div>LOADING</div>;
    } else {
      return (
        <>
          <div className="content">
            <Form className="row">
              <legend className="checkbox-group-legend">
                Modifier mes projets favoris
              </legend>
              <div>
                {this.state.favorite_projects_list.length === 0
                  ? "Veuillez sélectionner au moins 1 projet."
                  : ""}
              </div>
              <fieldset className="checkbox-group">
                {this.state.projects_list.map((project) => (
                  <div className="checkbox">
                    <label className="checkbox-wrapper">
                      <input
                        type="checkbox"
                        className="checkbox-input"
                        name={project.project_id}
                        onChange={this.updateCheckbox}
                        checked={
                          this.state.favorite_projects_list.includes(
                            parseInt(project.project_id, 10)
                          )
                            ? true
                            : false
                        }
                      />
                      <span className="checkbox-tile">
                        <span className="checkbox-icon"></span>
                        <span className="space">ID : {project.project_id}</span>
                        <span className="space">{project.project_name}</span>
                      </span>
                    </label>
                  </div>
                ))}
              </fieldset>

              <div className="persoS">
                <Button
                  className="btn btn-dark text-right"
                  variant="primary"
                  type="submit"
                  disabled={
                    this.state.favorite_projects_list.length === 0 ? true : false
                  }
                  onClick={(e) =>
                    this.update_favorite_projects_list_from_maintenance(e)
                  }
                >
                  filtre
                </Button>
              </div>
            </Form>
          </div>
        </>
      );
    }
  }
}

export default Filtre;
